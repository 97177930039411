<template>
  <div class="app">
    <div class="app-container">
      <div class="app-header">
        <h2 class="app-header_title">
          <img :src="cxfHost + `/public-assets/header-icon`" alt="canales de pago" class="img-icon img-margin-right">
          Localiza el más cercano
        </h2>
      </div>
      <div class="app-content">
        <div class="map-container">
          <div class="map-container_header">
            <div class="geocoder-container">
              <div class="input-group panel-list_input-group">
                <button class="btn btn-outline-success panel-list_button reset" type="button" @click="goToUserLocation">
                  <img class="btn-image" :src="cxfHost + `/public-assets/target-icon`">
                </button>
                <div id="geocoder"></div>
                <button class="btn btn-outline-success panel-list_button search" type="button" @click.prevent="dispatchSearchEvent()">
                  <img class="btn-image" :src="cxfHost + `/public-assets/search-icon`">
                </button>
                <button class="btn btn-outline-success panel-list_button filters" v-if="screenWidth <= 898" type="button" @click.prevent="openFiltersPanel()">
                  <img class="btn-image" :src="cxfHost + `/public-assets/filter-icon-mobile`">
                </button>
              </div>
            </div>
            <div></div>
            <div class="categories-container d-flex justify-content-end">
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item category" :class="{'category-selected': selectedCategory === category.slug}"
                    v-for="category in primaryCategories" :key="category" @click.prevent="selectCategory(category.slug)">
                  <img class="category-image" :src="cxfHost + `/public-assets${category.image}`">
                  <span>{{ category.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="map-container_content">
            <div id="map" class="map"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters panel -->
    <FiltersPanelVue
      :showPanel="showPanel"
      :categories="categories"
      :selectedCategory="selectedCategory"
      @applyClicked="selectCategory"
    />

    <LoadingModal v-if="status.isLoading"></LoadingModal>
  </div>
</template>

<script>
import FiltersPanelVue from './components/FiltersPanel.vue';
import ListItem from './components/ListItem.vue';
import mapMixin from '../../mixins/mapMixin';
import statsMixin from '../../mixins/statsMixin';
import LoadingModal from './LoadingModal.vue';

export default {
  inject: ['$axios'],
  mixins: [mapMixin,statsMixin],
  components: { ListItem, FiltersPanelVue, LoadingModal },
  props: {
    mapboxApiKey: {
      type: String,
      required: true
    },
    cxfHost: {
      type: String,
      required: true
    }
  },
  // Provide computed cxfHost
  provide() {
    return {
      cxfHost: this.cxfHost
    }
  },
  data() {
    return {
      map: null,
      userLocationMarker: null,
      userLocationCoords: null,
      freezedUserLocationCoords: [],
      locations: [],
      locationsResponse: [],
      categoriesFilters: ['ALL'],
      selectedLocation: null,
      selectedCategory: 'ALL',
      filterByCategories: {slugs: ['banco-azteca'], ids: [], type: 'all'},
      categories: [
        {
          title: 'Todos los canales de Pago',
          image: '/azteca-category',
          displayAsMain: true,
          hideAt: null,
          slug: 'ALL'
        },
        {
          title: 'Sucursales',
          image: '/azteca-category',
          displayAsMain: true,
          hideAt: null,
          slug: 'sucursales'
        },
        {
          title: 'Puntos BAZ',
          image: '/baz-category',
          displayAsMain: true,
          hideAt: null,
          slug: 'puntos-baz'
        }
      ],
      showPanel: false,
      screenWidth: null,
      isPermissionGranted: false,
      status: {
        isLoading: false
      }
    }
  },
  async created() {
    await this.requestUserLocation();
    window.addEventListener("resize", this.handleScreenChanges);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleScreenChanges);
  },
  async mounted() {
    // Generate uuid for current user
    this.generateUserIdentifier();
    // Get current screen width
    this.screenWidth = window.innerWidth;
    this.initializeMap();
    let geocoder = this.createGeocoder();
    let mobilegeocoder = this.createGeocoder();
    await this.map.on('load', () => {
      const searchInputHandler = (payload) => {
        if (payload?.result?.geometry?.coordinates) {
          this.userLocationCoords = payload.result.geometry.coordinates;
          this.navigateTo(this.userLocationCoords);
          // Save stats
          let search = payload?.result?.place_name;
          let zipCode = payload?.result?.context?.length > 0 ? payload.result.context[0].text : null;
          let locationCoords = payload?.result?.center ? payload.result.center : [];
          this.saveStatistics('search_input', {search: search, zip_code: zipCode, search_coordinates: locationCoords});
        }
      }
      geocoder.on('result', async (event) => searchInputHandler(event));
      mobilegeocoder.on('result', async (event) => searchInputHandler(event));
    });

    document.getElementById('geocoder').appendChild(geocoder.onAdd(this.map));
    // document.getElementById('mobilegeocoder').appendChild(mobilegeocoder.onAdd(this.map));

    navigator.geolocation.watchPosition(() => {
      this.isPermissionGranted = true;
    });
  },
  watch: {
    userLocationCoords: {
      async handler(newVal) {
        if (newVal) {
          this.navigateTo(newVal);
          await this.getLocations();
        }
      },
      immediate: true,
    },
    locations: {
      handler(newVal) {
        if (newVal) {
          this.drawPinForLocations(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    filterByCategories: {
      async handler(newVal) {
        if (newVal && this.userLocationCoords) {
          await this.getLocations();
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    resultsText() {
      let text = 'No hay resultados cerca'
      if (this.locations && this.locations.length > 1) {
        text = `Hay ${this.locations.length} resultados cerca`
      } else if (this.locations && this.locations.length === 1) {
        text = `Hay ${this.locations.length} resultado cerca`
      }
      return text;
    },
    primaryCategories() {
      let categories = [];
      if (this.categories) {
        categories = this.categories.filter(c => c.displayAsMain === true && (this.screenWidth > c.hideAt));
      }
      return categories;
    },
    secondaryCategories() {
      let categories = [];
      if (this.categories) {
        categories = this.categories.filter(c => c.displayAsMain === false || (this.screenWidth < c.hideAt));
      }
      return categories;
    }
  },
  methods: {
    initializeMap() {
      // mapboxgl.accessToken = "pk.eyJ1IjoiYmF6LWNvYnJhbnphIiwiYSI6ImNsZjdwbWhlMzF5aHg0MnBqdWN1MjA3aHIifQ.kn4jurtr8lwLaTkvcwfJ0g";
      mapboxgl.accessToken = `${this.mapboxApiKey}`;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-99.14556, 19.41944], //CDMX by default
        zoom: 15,
      });

      this.map.on('load', (event) => {
        this.map.resize();
      });
    },
    createGeocoder() {
      let options = {
        countries: 'MX',
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Direccion, C.P o colonia',
        language: 'es-MX',
      }
      return new MapboxGeocoder(options)
    },
    navigateTo(coords) {
      // Fly effect to current coords
      this.map.flyTo({
        zoom: 15,
        center: coords
      });
      // Clear previous user location if exists
      if (this.userLocationMarker) {
        this.userLocationMarker.remove()
      }
      // Set user location pin according coords
      this.userLocationMarker = new mapboxgl.Marker()
      .setLngLat(coords)
      .addTo(this.map);
    },
    async getLocations(long = null, lat = null) {
      const longitude = long ?? this.userLocationCoords[0];
      const latitude = lat ?? this.userLocationCoords[1];
      this.status.isLoading = true;
      await this.$axios.get(`/api/v1/locations/get_locations_from_cxf?longitude=${longitude}&latitude=${latitude}`)
          .then(response => {
            this.locationsResponse = response.data.data;
            this.applyFiltersToResponse();
          })
          .finally(() => {
            this.status.isLoading = false;
          })
    },
    drawPinForLocations(locations) {
      // Clean previous drawed pins if exists
      const elements = document.getElementsByClassName("marker");
      while (elements.length > 0) elements[0].remove();
      locations.forEach((location, index) => {
        const popupImage = location?.content_instance?.location_details?.pin_image?.public_path ?? '/public-assets/baz-pin';
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
          `<div>
            <div>
                <img src=\"${this.cxfHost}${popupImage}\" width=\"35px\" height=\"45px\"><h3>${location.title}</h3>
            </div>
            <p style="text-transform: capitalize !important;">${location?.content_instance?.location?.address}</p>
            <div style=\"display: flex;justify-content: space-between;\">
              <a target=\"_blank\" href=\"${this.generateGoogleURL(location?.content_instance?.location?.geometry?.coordinates, this.userLocationCoords)}\" class=\"buttons__button\"><img src=\"${this.cxfHost}/public-assets/google-icon\" width=\"14px\" height=\"14px\" class=\"img-margin-right img-margin-bottom\">Ir con maps</a>
              <a target=\"_blank\" href=\"${this.generateWazeURL(location?.content_instance?.location?.geometry?.coordinates, this.userLocationCoords)}\" class=\"buttons__button\"><img src=\"${this.cxfHost}/public-assets/waze-icon\" width=\"14px\" height=\"13px\" class=\"img-margin-right img-margin-bottom\">Ir con waze</a>
            </div>
          </div>`
        ).setMaxWidth("300px");
        // Build HTML element for pin
        var el = document.createElement('div');
        el.index = index;
        el.id = `location-${location.id}`;
        el.className = `marker`;
        el.style.backgroundImage  = `url(${this.cxfHost}${popupImage})`;
        // Add click event listener to save stats
        el.addEventListener('click', (event) => {
          this.saveStatistics('pin_clicked', null);
        });
        // Create mapbox marker instance and add it to current map
        new mapboxgl.Marker(el)
        .setLngLat(location?.content_instance?.location?.geometry?.coordinates)
        .setPopup(popup)
        .addTo(this.map);
      });
    },
    dispatchSearchEvent() {
      // Find mapbox geocoder input
      const inputs = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
      const input = inputs[0];
      var event = new KeyboardEvent('keydown', {
        bubbles: true, cancelable: true, keyCode: 13
      });
      input.dispatchEvent(event);
    },
    selectCategory(categorySlug) {
      this.selectedCategory = categorySlug;
      this.applyFiltersToResponse();
      this.showPanel = false;
    },
    openFiltersPanel() {
      this.showPanel = true;
    },
    getUserLocationFromBackend() {
      this.isGeolocationGranted = false;
      this.$axios.get(`/api/v1/locations/user_location`)
      .then(response => {
        if (response.data && response.data?.location.length > 0) {
          this.userLocationCoords = [response.data.location[1],response.data.location[0]]
          this.freezedUserLocationCoords = [response.data.location[1],response.data.location[0]]
        }
      })
      .finally(() => {
        this.status.isLoading = false;
      })
    },
    handleScreenChanges(data) {
      this.screenWidth = data.srcElement.innerWidth;
    },
    applyFiltersToResponse() {
      if (this.selectedCategory === 'ALL') {
        this.locations = this.locationsResponse;
      } else {
        this.locations = this.locationsResponse
            .filter(item => item?.taxonomies?.length > 0 && item.taxonomies[0].slug === this.selectedCategory);
      }
    },
    async requestUserLocation() {
      this.status.isLoading = true;
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }
        navigator.geolocation.getCurrentPosition(position => {
          const {longitude, latitude} = position.coords;
          this.userLocationCoords = [longitude, latitude]
          this.freezedUserLocationCoords = [longitude, latitude]
          this.isPermissionGranted = true;
          this.saveStatistics('geolocation_granted', null);
          resolve();
        }, err => {
          // If user deny geolocation permission, get location from backend
          this.getUserLocationFromBackend();
          this.saveStatistics('geolocation_granted', null);
        });
      });
    },
    async goToUserLocation() {
      await this.getLocations(this.freezedUserLocationCoords[0], this.freezedUserLocationCoords[1]);
      this.navigateTo(this.freezedUserLocationCoords);
    },
  }
}
</script>``

<style lang="scss">
.app {
  display: flex;
  justify-content: center;
  padding: 1em 0em 0em 0em;
}
.app-header_mobile {
  width: 100%;
}
.app-container {
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 94%;
}
.app-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-header_title {
  display: flex;
  align-content: center;
  font-size: 25px;
  font-weight: 700;
  padding: 1em 0em 1em 0em;
}
.app-content {
  display: flex;
  width: 100%;
}
.btn-image {
  height: 20px!important;
  width: 20px!important;
}
.panel-list {
  flex: 3;
  padding: 10px;
  min-width: 390px;
}
.panel-list_title {
  color:#282828;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 1em;
}
.panel-list_input-container {
  margin-bottom: 1em;
}
.map {
  width: 100%!important;
  height: 100%;
  max-height: 590px;
}
.map-container {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 100%!important;
}
.panel-list_button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 40px;
  height: auto;
}
.search {
  background: #43b02a;
}
.reset {
  /* background: #f#3f3f3; */
  background: url("../images/buttonbg.png") no-repeat scroll 0 0 transparent;
}
.filters {
  background: #FFFFFF;
}
.panel-list_results {
  color: #282828;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 1em;
}
.panel-list_input-group {
  align-content: center;
  display: flex;
}
#geocoder {
  flex-grow: 1;
}
#mobilegeocoder {
  flex-grow: 1;
}
.mapboxgl-ctrl-geocoder--input {
  color: rgba(0,0,0,.75)!important;
  font-size: 15px!important;
}
.mapboxgl-ctrl-geocoder--button {
  border: none!important;
  background: transparent!important;
  top: -3px!important;
}
.panel-list_items {
  height: 570px;
  overflow-y: auto;
  padding: 0px 5px 0px 0px;
}
.header-title {
  font-weight: 600;
}
.category {
  align-items: center;
  border: none!important;
  border-right: #EAEAEA solid 1px !important;
  border-radius: 0px!important;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  gap: 5px;
  justify-content: center;
  height: 36px;
  padding: 2px 7px 2px 7px;
}
.category-image {
  height: 26px;
  width: 26px;
  margin-right: 0px;
}
.category-selected {
  border-bottom: solid 3px #17a54d!important;
}
.secondary-category {
  display: flex;
  border-radius: 0px!important;
  align-content: center;
  gap: 5px;
  font-size: 13px;
  padding: 5px 10px;
}
.dropdown-menu {
  min-width: 170px!important;
}
.dropdown-toggle {
  font-size: 13px;
}
.mobile_input-group {
  display: flex;
  justify-content: space-between;
}
.mobile-locations {
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  max-width: 852px;
}
.mobile-results {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 1em 0em 1em 0em;
}
.mobile-results > label {
  color: grey!important;
}
.filter-button {
  align-content: center;
  background: #43b02a;
  color: white;
  display: flex;
  justify-content: space-between;
  height: 36px;
  min-width: 120px;
}
.filter-button > label {
  color: white;
  font-style: normal;
}
.mobile-container {
  display: flex;
  flex-direction: column!important;
  justify-content: space-around!important;
  flex-grow: 1;
  padding: 15px 0px 5px 0px;
}
.geocoder-container {
  flex: 7;
  max-width: 450px;
}
.categories-container {
  flex: 3;
}
.map-container_header {
  display: flex;
}
.map-container_content {
  flex: 8;
}
.map-mobile_list {
  flex: 1;
}
@media only screen and (max-width: 898px) {
  #geocoder {
    flex-grow: 1;
    width: 90px;
  }
  .categories-container {
    display: none!important;
  }
}
.list-group-item {
  padding-right: 2em;
}
.mapboxgl-ctrl-geocoder {
  height: 100%!important;
}
.mapboxgl-ctrl-geocoder--input {
  height: 40px!important;
}
.mapboxgl-ctrl-geocoder--icon-close {
  background: transparent!important;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-right: 3px;
}
.img-icon {
  height: 30px;
  width: 31px;
}
</style>